<template>
  <div class="warpbg">
    <div class="frame_rank">
        <div class="box_rank">
          <div class="one">
              <!-- 游戏头像 -->
              <img v-if="rankList[0]" :src="rankList[0]['avatar']" class="one_image" alt="" >
              <!--  -->
              <div class="game">
                <div v-if="rankList[0]" class="server">{{ rankList[0]['server'] }}</div>
                <div class="role_name" v-if="rankList[0]" >{{ rankList[0]['role_name'] }}</div>
              </div>
          </div>
          <div class="one">
              <!-- 游戏头像 -->
              <img v-if="rankList[1]" :src="rankList[1]['avatar']" class="one_image" alt="" >
              <!--  -->
              <div class="game">
                <div class="server" v-if="rankList[1]" >{{ rankList[1]['server'] }}</div>
                <div class="role_name" v-if="rankList[1]" >{{ rankList[1]['role_name'] }}</div>
              </div>
          </div>

          <div class="one">
              <!-- 游戏头像 -->
              <img v-if="rankList[1]" :src="rankList[2]['avatar']" class="one_image" alt="" >
              <!--  -->
              <div class="game">
                <div class="server" v-if="rankList[2]" >{{ rankList[2]['server'] }}</div>
                <div class="role_name" v-if="rankList[2]" >{{ rankList[2]['role_name'] }}</div>
              </div>
          </div>
        </div>
        <!-- 活动顺明 -->
        <div class="box_info">
          <div class="box_content">
            <div class="cell_info_title">活动说明:</div>
            <div  class="cell_info_text" v-html="desc"></div>
          </div>
        </div>
    </div>
  </div>

    
</template>

<script>
import { getSummerParty } from '@/api/mrt'
export default {
  data () {
    return {
      numberId: 4,
      isLogin: false,
      loginShow: false,
      account: undefined,
      logout: false,
      rankList: [],
      giftList: [],
      desc: undefined,
      remark: undefined,
      comment: [],
      scrollClassOption: {
        step:0.3,
        limitMoveNum: 1
      },
      showCommentInput: false,
      stateSubmitComment: true,
      rankComment: undefined,
      roleSelectShow: false,
      userInfo: undefined,
    }
  },
  created () {
    this.initView()
    this.getMrtApi()
  },
  methods: {
    initView () {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
      document.documentElement.style.fontSize = window.innerWidth / 7.5 + "px";
    },
    getMrtApi () {
      getSummerParty({ number_id: this.numberId }).then((res) => {
        this.desc = '<p style="font-size:0.3rem">' + res.desc.replace(/\n/g, '</p><p style="font-size:0.3rem">') + '</p>'
        this.remark = res.remark
        this.rankList = res.mrt_ranks
        this.comment = []
        this.rankList.forEach((val, index) => {
          this.rankList[index]['money'] = parseInt(val['money'])
          if (!val['avatar']) {
            this.rankList[index]['avatar'] = require('@/assets/activity/summerParty/avatar.jpg')
          }
          if (val.comment) {
            this.comment.push(val.role_name + '：' + val.comment)
          }
          
        })
      console.log(this.rankList)
      })
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.warpbg {
  width: 100%;
  height: 45rem;
}


.frame_rank {
  background: url('../../assets/activity/summerParty/bg.jpg') top center no-repeat;
  height: 45rem;
  background-size: cover;
  position: relative;
  margin: 0 auto;
}


.box_rank {
    width: 7.1rem;
    height: 3.6rem;
    position: absolute;
    top: 10.78rem;
    left: 0.15rem;
}
.one{
    width: 2.35rem;
    height: 100%;
    float: left;
}

.one_image {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: 0.7rem;
    margin-top: 0.88rem;
    border-radius: 0.2rem 0.15rem;
}

.game {
    width: 2.3rem;
    height: 1.21rem;
    margin-top: 0.2rem;
}
.server{
  width: 2rem;
  height: 0.32rem;
  font-size: 0.32rem;
  font-family: NSimSun;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
  line-height: 0.32rem;
  text-shadow: 0px 5px 4px #000000;
  margin: 0 auto;
}

.role_name {
    width: 2rem;
    height: 0.32rem;
    font-size: 0.32rem;
    margin-top: 0.2rem;
    font-family: NSimSun;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 0.32rem;
    text-align: center;
    text-shadow: 0px 5px 4px #000000;
    margin: 0.2rem auto;
}

.box_info {
    position: absolute;
    margin-top: 36.5rem;
    width: 100%;
    height: 8.5rem;
    color: rgb(249, 209, 87);
}

.box_content{
  width: 90%;
  margin: 0 auto;
  font-size: 0.32rem;
  font-family: NSimSun;
  font-weight: 400;
  color: #FFFFFF;
}
.cell_info_title {
  width: 100%;
}
.cell_info_text {
  font-size: 0.2rem;
}
.box_info p {
  margin: 0.15rem auto;
}



</style>